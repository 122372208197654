










import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import EventBus from "@/event-bus";

import { breakpointWidthFilter } from "@/plugins/tailwind/filters/breakpoint-width.filter";

import { AppOverlayOptions } from "@/plugins/overlay/interfaces/app-overlay-options";
import { AppOverlayCloseEventData } from "@/plugins/overlay/interfaces/app-overlay-close-event-data";

import { TailwindBreakpoint } from "@/plugins/tailwind/types/tailwind-breakpoint";

import { OverlayEvent } from "@/plugins/overlay/enums/overlay-event.enum";
import { OverlayCloseType } from "@/plugins/overlay/enums/overlay-close-type.enum";

@Component({
  components: {
    OverlayFadeTransition: () =>
      import(
        /* webpackChunkName: 'app-overlay-fade-transition' */ "@/plugins/overlay/transitions/OverlayFadeTransition.vue"
      )
  }
})
export default class AppOverlay extends Vue {
  /** Breakpoint observer */
  private mediaMatcher!: MediaQueryList;

  /** Information whether overlay is opened */
  isOpened = false;

  /** Overlay options */
  options: AppOverlayOptions = {};

  /** Returns current overlay breakpoint */
  get breakpoint(): TailwindBreakpoint | undefined {
    return this.options?.breakpoint;
  }

  created(): void {
    EventBus.$on(OverlayEvent.OverlayOpenRequested, this.overlayOpenRequested);
    EventBus.$on(
      OverlayEvent.OverlayCloseRequested,
      this.overlayCloseRequested
    );
  }

  /** Handles overlay click event - emits close event with internal close type */
  overlayClicked(): void {
    EventBus.$emit(OverlayEvent.OverlayCloseRequested, {
      closeType: OverlayCloseType.Internal
    } as AppOverlayCloseEventData);
  }

  /**
   * Handles overlay open request event
   * @param options Overlay options
   * @private
   */
  private overlayOpenRequested(options: AppOverlayOptions): void {
    if (!this.isOpened) {
      this.resetOptions();
      this.isOpened = true;
      this.options = options;

      // Disable scrolling
      this.disableScroll();

      // Set media matcher if breakpoint option is specified
      if (this.breakpoint) {
        const width = breakpointWidthFilter(this.breakpoint);
        this.mediaMatcher = matchMedia(`(min-width: ${width}px)`);
        this.mediaMatcher.addEventListener("change", this.mediaChanged);
      }
    }
  }

  /**
   * Handles overlay close request event
   * @private
   */
  private overlayCloseRequested(): void {
    if (this.isOpened) {
      this.resetOptions();
      this.isOpened = false;

      // Enable scrolling
      this.enableScroll();

      // Destroy media matcher by removing event listener
      this.mediaMatcher.removeEventListener("change", this.mediaChanged);
    }
  }

  /** Enables page scrolling */
  private enableScroll(): void {
    enableBodyScroll(document.body);
  }

  /** Disables page scrolling */
  private disableScroll(): void {
    disableBodyScroll(document.body);
  }

  /**
   * Resets overlay options
   * @private
   */
  private resetOptions(): void {
    this.options = {};
  }

  /**
   * Handles media change event when breakpoint option is specified
   * @param event Media query list event
   * @private
   */
  private mediaChanged(event: MediaQueryListEvent): void {
    if (event.matches) {
      this.enableScroll();
    } else {
      this.disableScroll();
    }
  }
}
