import { TailwindBreakpoint } from "@/plugins/tailwind/types/tailwind-breakpoint";
import { TailwindBreakpointWidth } from "@/plugins/tailwind/enums/tailwind-breakpoint-width.enum";

/**
 * Tailwind breakpoint width filter.
 * It returns breakpoint width based on given variant
 * @param breakpoint Breakpoint name
 */
export const breakpointWidthFilter: (
  breakpoint: TailwindBreakpoint
) => TailwindBreakpointWidth = (breakpoint: TailwindBreakpoint) => {
  const width: { [key in TailwindBreakpoint]: TailwindBreakpointWidth } = {
    sm: TailwindBreakpointWidth.Small,
    md: TailwindBreakpointWidth.Medium,
    lg: TailwindBreakpointWidth.Large,
    xl: TailwindBreakpointWidth.ExtraLarge
  };
  return width[breakpoint];
};
